import Head from "next/head";

import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import {
  Block01,
  Block02,
  Block03,
  Block04,
  Block05,
  Block06,
  Block07,
  Block08,
} from "../blocks";
import { Header, Footer } from "../components";

export default function Home() {
  return (
    <>
      <div className="container">
        <Head>
          <title>Braex - Brazilian Exchange Market</title>
          <meta
            property="og:title"
            content="Bra.ex - Brazilian Exchange Market"
          />
          <meta
            name="description"
            content="Plataforma eletrônica de negociação de criptoativos do Ecossistema 2ND Market."
          />
          <meta
            property="og:description"
            content="Plataforma eletrônica de negociação de criptoativos do Ecossistema 2ND Braex."
          />
          <meta property="og:site_name" content="Braex" />
          <meta property="og:url" content={ProductUrls.BRAEX} />
          <meta property="og:image" content="/img/banner.jpg" />
          <meta property="og:image:width" content="1080" />
          <meta property="og:image:height" content="1080" />
          <meta
            name="twitter:card"
            content="summary_large_image"
          />
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:type" content="website" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <Header />
        <Block01 />
        <Block02 />
        <Block03 />
        <Block04 />
        <Block05 />
        <Block06 />
        <Block07 />
        <Block08 />
        <Footer />
      </div>
    </>
  );
}
